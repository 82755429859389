<template>
    <div class="EmployeeDetails">
        <!-- Back Arrow to return to the previous page -->
        <BackArrow variant="black" :to="'/savings-calculator'" />

        <div class="PageHeader">
            <div>
                <Headline title="Rücklagenrechner"></Headline>
                <h2 class="EmployeeName">{{ employee?.name }}</h2>
            </div>
            <div>
                <!-- Action Buttons -->
                <div class="ActionButtons">
                    <Button @onClick="showSettingsModal" variant="basic" size="small">
                        Einstellung
                    </Button>
                    <Button @onClick="showNewEntryModal" variant="basic" size="small">
                        Ausgabe Eintragen
                    </Button>
                    <Button @onClick="showNewEntryModal" variant="basic" size="small">
                        Einnahme Eintragen
                    </Button>
                    <Button @onClick="showSavingsEntryModal" variant="" size="small">
                        Rücklage Bilden
                    </Button>
                </div>
            </div>
        </div>

        <!-- Summary Dashboard -->
        <div class="SummaryDashboard">
            <div class="SummaryCard">
                <h4>Umsatz</h4>
                <h2>{{ formatEuro(employee?.income) }}</h2>
            </div>
            <div class="SummaryCard">
                <h4>Umsatz Bar (39%)</h4>
                <h2>{{ formatEuro(employee?.income) }}</h2>
            </div>
            <div class="SummaryCard">
                <h4>Umsatz Bargeldlose (71%)</h4>
                <h2>
                    {{ formatEuro(profit) }}
                </h2>
                <span
                    class="info-icon"
                    v-tooltip="{ content: creditCardInfo, html: true, placement: 'top' }"
                >
                    ⓘ
                </span>
            </div>
            <div class="SummaryCard">
                <h4>Ausgaben</h4>
                <h2 :class="{ 'text-negative': totalExpenseAmount < 0 }">
                    {{ formatEuro(totalExpenseAmount) }}
                </h2>
            </div>

            <div class="SummaryCard">
                <h4>Zwischensumme</h4>
                <h2 :class="{ 'text-positive': profit > 0 }">
                    {{ formatEuro(profit) }}
                </h2>
            </div>

            <div class="SummaryCard">
                <h4>Rücklage (47%)</h4>
                <h2>{{ formatEuro(share) }}</h2>
            </div>

            <div class="SummaryCard">
                <h4>Ergenis</h4>
                <h2>{{ formatEuro(open) }}</h2>
            </div>
        </div>

        <div class="ChartButtons">
            <div
                class="IconButton"
                :class="{ isActive: chartType === 'bar' }"
                @click="chartType = 'bar'"
                v-tooltip="`Ausgaben in Barchart anzeigen`"
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M3 12H7V21H3V12ZM17 8H21V21H17V8ZM10 2H14V21H10V2Z"></path>
                </svg>
            </div>
            <div
                class="IconButton"
                :class="{ isActive: chartType === 'pie' }"
                @click="chartType = 'pie'"
                v-tooltip="`Ausgaben in Piechart anzeigen`"
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path
                        d="M11 2.04938V13H21.9506C21.4489 18.0533 17.1853 22 12 22C6.47715 22 2 17.5229 2 12C2 6.81465 5.94668 2.5511 11 2.04938ZM13 2.04938C17.7244 2.51845 21.4816 6.27559 21.9506 11H13V2.04938Z"
                    ></path>
                </svg>
            </div>
        </div>
        <!-- Chart for Employee Expenses -->
        <ChartV2
            v-if="chartData.length"
            groupByService
            renderSeparately
            direction="horizontal"
            :type="chartType"
            :secondValues="chartComparisonData"
            :title="chartTitle"
            :secondValuesTitle="chartComparisonTitle"
            :values="chartData"
            :serviceColors="serviceColors"
            :activeBar="activeBar"
        />

        <!-- Expenses Table -->
        <div class="FilterButtons">
            <button
                v-for="category in categories.filter(c => !c.inFilterHidden)"
                :key="category.id"
                :class="{ isActive: selectedFilter.id === category.id }"
                class="FilterButton"
                @click="handleFilterSelect(category)"
            >
                {{ category.value }}
            </button>
        </div>
        <div class="ExpensesAndRevenueTableSection">
            <div class="RevenueTableSection">
                <h3>Einnahmen</h3>
                <ExpensesTable :expenses="filteredRevenue" @onClick="handleRowClick" />
            </div>
            <div class="ExpensesTableSection">
                <h3>Ausgaben</h3>
                <ExpensesTable :expenses="filteredExpenses" @onClick="handleRowClick" />
            </div>
        </div>
        <Modal
            :title="'Einstellung'"
            :show="settingsModal.active"
            :action="{ text: 'Speichern', color: 'dark', callback: saveSettings }"
            :cancel="{ text: 'Abbrechen' }"
            @onModalClose="handleSettingsModalClose"
        >
            <p>Tragen Sie hier den Anteil ein, der von den Rücklagen übernommen wird.</p>

            <div class="SettingsInputs">
                <div
                    v-for="setting in categories.filter(c => !c.hidden)"
                    :key="setting.id"
                    class="ServiceInput"
                >
                    <label>{{ setting.value }}</label>
                    <Input
                        type="text"
                        v-model.lazy="setting.percent"
                        pattern="[0-9]+([,][0-9]+)?"
                        placeholder="0%"
                    />
                </div>
            </div>
        </Modal>
        <Modal
            :title="'Ausgabe Eintragen'"
            :show="expenseModal.active"
            :action="{ text: 'Speichern', color: 'dark', callback: saveExpense }"
            :cancel="{ text: 'Abbrechen' }"
            @onModalClose="handleExpenseModalClose"
        >
            <div>
                <p>Tragen Sie eine Ausgabe des Fahrers ein.</p>
                <br />

                <!-- Kategorie -->
                <div>
                    <label for="category" style="display:block">Kategorie</label>
                    <Dropdown
                        hasSearch
                        :items="categories"
                        :selected="selectedCategory.value"
                        variant="single-column"
                        placeholder="Kategorie auswählen"
                        @onItemSelect="handleCategorySelect"
                    />
                    <br />
                    <Input
                        v-if="selectedCategory?.id === 'custom'"
                        type="text"
                        id="category"
                        name="category"
                        keyName="category"
                        placeholder="Eigene Kategorie"
                        :value="expenseModal.category"
                        @onKeyPress="handleCustomCategoryChange"
                    />
                </div>
                <br />
                <Input
                    :value="expenseModal.date"
                    label="Datum der Ausgabe"
                    type="date"
                    placeholder="Datum auswählen"
                    direction="vertical"
                    @onKeyPress="handleDateChange"
                /><br />
                <!-- Betrag -->
                <div>
                    <label for="amount">Betrag</label>
                    <Input
                        type="text"
                        id="amount"
                        name="amount"
                        keyName="amount"
                        placeholder="40,50"
                        :value="expenseModal.amount"
                        @onKeyPress="handleAmountChange"
                    />
                </div>
            </div>
        </Modal>
        <Modal
            :title="'Rücklage Bilden'"
            :show="savingsEntryModal.active"
            :action="{ text: 'Speichern', color: 'dark', callback: saveSavingsEntry }"
            :cancel="{ text: 'Abbrechen' }"
            @onModalClose="handleSavingsEntryModalClose"
        >
            <div>
                <p>Tragen Sie hier die Rücklage ein.</p>
                <br />
                <!-- Datum -->
                <Input
                    :value="savingsEntryModal.date"
                    label="Datum"
                    type="date"
                    placeholder="Datum auswählen"
                    @onKeyPress="handleSavingsEntryDateChange"
                /><br />

                <!-- Betrag -->
                <div>
                    <label for="savingsAmount">Betrag (max: {{ formatEuro(open) }})</label>
                    <Input
                        type="number"
                        id="savingsAmount"
                        name="savingsAmount"
                        :max="open"
                        placeholder="Betrag eingeben"
                        :value="savingsEntryModal.amount"
                        @onKeyPress="handleSavingsEntryAmountChange"
                    />
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { faker } from '@faker-js/faker';
import { mapState } from 'vuex';
import Modal from '@/components/widgets/Modal.vue';
import Input from '@/components/widgets/Input.vue';
import Dropdown from '@/components/widgets/Dropdown.vue';

import { format, startOfMonth, subDays } from 'date-fns';
import { euro } from '@/lib/helper';
import Headline from '@/components/Headline.vue';
import Button from '@/components/widgets/Button.vue';
import BackArrow from '@/components/BackArrow.vue';
import ChartV2 from '@/components/widgets/ChartV2.vue';
import ExpensesTable from './components/ExpensesTable.vue';

export default {
    name: 'EmployeeDetails',
    components: {
        ExpensesTable,
        Headline,
        BackArrow,
        Button,
        ChartV2,
        Modal,
        Input,
        Dropdown,
    },
    data() {
        return {
            employee: null,
            chartType: 'bar',
            activeBar: -1,
            expenses: [],
            revenues: [], // Track revenue separately
            expenseModal: {
                active: false,
                category: '',
                date: new Date().toISOString(),
                description: '',
                amount: '',
            },
            selectedFilter: { id: 'all', value: 'Alle' },
            settingsModal: {
                active: false,
            },

            selectedCategory: { value: 'Tanken', id: 'tanken' },
            savingsEntryModal: {
                active: false,
                date: new Date().toISOString(),
                amount: '',
            },
            categories: [
                { value: 'Alle', id: 'all', hidden: true },
                {
                    value: 'Rücklage',
                    id: 'saving',
                    hidden: true,
                    inFilterHidden: true,
                    percent: '47%',
                },
                { value: 'Uber', id: 'uber', percent: '100%', color: '#5C5C60' }, // Slightly desaturated dark gray
                { value: 'FreeNow', id: 'freeNow', percent: '50%', color: '#D85670' }, // Muted reddish-pink
                { value: 'Bolt', id: 'bolt', percent: '100%', color: '#5FC9B3' }, // Softer teal-green
                { value: 'Bliq', id: 'bliq', percent: '100%', color: '#F472A5' }, // Muted pink
                { value: 'Coupon', id: 'coupon', percent: '100%', color: '#7FDA97' }, // Muted green
                { value: 'Apcoa', id: 'apcoa', percent: '100%', color: '#55C0E0' }, // Softer cyan-blue
                { value: 'Funk', id: 'funk', percent: '100%', color: '#F4D05D' }, // Softer yellow
                { value: 'Tanken', id: 'tanken', type: 'expense', color: '#F99E60' }, // Softer orange
                { value: 'Werkstatt', id: 'werkstatt', type: 'expense', color: '#8967E0' }, // Softer purple
                { value: 'Autowäsche', id: 'autowäsche', type: 'expense', color: '#5EA8F7' }, // Softer blue
                { value: 'TÜV', id: 'tuv', type: 'expense', color: '#66A7D6' }, // Softer lighter blue
                {
                    value: 'SV',
                    id: 'sozialversicherung',
                    percent: '100%',
                    type: 'expense',
                    color: '#E67272',
                }, // Muted red
                { value: 'Sonstiges', id: 'custom', color: '#BFC1C6' }, // Softer gray
            ],
        };
    },
    computed: {
        serviceColors() {
            // Create a lookup table from categories
            return this.categories.reduce((acc, category) => {
                if (category.color) {
                    acc[category.value] = category.color;
                }
                return acc;
            }, {});
        },
        filteredExpenses() {
            const filtered =
                this.selectedFilter.id === 'all'
                    ? this.expenses
                    : this.expenses.filter(
                          expense => expense.service === this.selectedFilter.value,
                      );

            return filtered;
        },
        filteredRevenue() {
            const filtered =
                this.selectedFilter.id === 'all'
                    ? this.revenues
                    : this.revenues.filter(
                          revenue => revenue.service === this.selectedFilter.value,
                      );

            return filtered;
        },
        expensesAndRevenue() {
            const filteredExpenses =
                this.selectedFilter.id === 'all'
                    ? this.expenses
                    : this.expenses.filter(
                          expense => expense.service === this.selectedFilter.value,
                      );

            const combinedEntries = filteredExpenses
                .map(entry => ({
                    value: entry.type === 'Expense' ? entry.description : 'Revenue',
                    income: entry.type === 'Revenue' ? entry.amount : 0,
                    expense: entry.type === 'Expense' ? -entry.amount : 0,
                    isCompleted: entry.type === 'Revenue',
                    completedAt: entry.type === 'Revenue' ? entry.date : null,
                    color: entry.color,
                    ...entry,
                }))
                .sort(
                    (a, b) => new Date(a.completedAt || a.date) - new Date(b.completedAt || b.date),
                );

            return combinedEntries;
        },

        totalExpenseAmount() {
            return this.expensesAndRevenue.reduce((acc, entry) => {
                return acc + (entry.expense ? entry.expense : 0);
            }, 0);
        },
        creditCardInfo() {
            return `<p style="font-size: 0.75rem;">
                    iZettel: 504,50€ <br />
                    MyPos: 10,30€ <br />
                    SumUp: 140,30€
                </p>`;
        },
        profit() {
            console.log('profit', this.employee?.income, this.totalExpenseAmount);
            return this.employee ? this.employee.income * 100 + this.totalExpenseAmount : 0;
        },
        share() {
            return this.profit * 0.47;
        },
        open() {
            return this.share;
        },
        chartTitle() {
            const category = this.selectedFilter.id === 'all' ? '' : this.selectedFilter.value;
            const categoryText = category ? ` (${category})` : '';

            return `Einnahmen ${categoryText}`;
        },
        chartComparisonTitle() {
            const category = this.selectedFilter.id === 'all' ? '' : this.selectedFilter.value;
            const categoryText = category ? ` (${category})` : '';

            return `Ausgaben ${categoryText}`;
        },

        chartData() {
            return this.filteredRevenue
                .map(({ amount, date, ...rest }) => ({
                    formattedValue: euro(amount).format(),
                    formatter: value => euro(value).format(),
                    rawDate: date, // Make sure this value is used for x-axis categories
                    value: amount,
                    date: date,
                    ...rest,
                }))
                .sort((a, b) => new Date(a.date) - new Date(b.date)); // Pie chart only for revenues
        },
        chartComparisonData() {
            const expenses = this.filteredExpenses
                .map(({ amount, date, ...rest }) => ({
                    formattedValue: euro(amount).format(),
                    formatter: value => euro(value).format(),
                    rawDate: date, // Make sure this value is used for x-axis categories
                    value: amount,
                    date: date,
                    ...rest,
                }))
                .sort((a, b) => new Date(a.date) - new Date(b.date)); // Pie chart only for revenues

            return expenses;
        },
    },
    methods: {
        // Show the modal
        showSavingsEntryModal() {
            this.savingsEntryModal.date = new Date().toISOString(); // reset date to today's date
            this.savingsEntryModal.amount = ''; // reset the amount
            this.savingsEntryModal.active = true; // show the modal
        },

        // Close the modal
        handleSavingsEntryModalClose() {
            this.savingsEntryModal.active = false;
            this.savingsEntryModal.date = ''; // clear date
            this.savingsEntryModal.amount = ''; // clear amount
        },

        // Validate the date input
        handleSavingsEntryDateChange({ value }) {
            this.savingsEntryModal.date = value;
        },

        // Validate the amount input
        handleSavingsEntryAmountChange({ value }) {
            const amount = parseFloat(value || '0');
            if (amount > this.open) {
                this.savingsEntryModal.amount = this.open;
            } else if (amount < 0) {
                this.savingsEntryModal.amount = 0;
            } else {
                this.savingsEntryModal.amount = amount;
            }
        },

        // Save the savings entry
        saveSavingsEntry() {
            // Add logic to save savings entry (e.g., API call)
            console.log('Savings Date:', this.savingsEntryModal.date);
            console.log('Savings Amount:', this.savingsEntryModal.amount);
            this.handleSavingsEntryModalClose(); // Close the modal after saving
        },

        showNewEntryModal() {
            // Trigger to show modal
            this.expenseModal.active = true;
        },
        handleExpenseModalClose() {
            this.expenseModal.active = false;
            this.expenseModal.category = '';
            this.expenseModal.amount = '';
            this.expenseModal.date = new Date().toISOString().slice(0, 10);
            this.selectedCategory = { value: 'Tanken', id: 'tanken' };
        },
        handleCategorySelect({ item }) {
            this.selectedCategory = item;
            if (item.id !== 'custom') {
                this.expenseModal.category = item.value;
            } else {
                this.expenseModal.category = '';
            }
        },
        handleAmountChange({ value }) {
            this.expenseModal.amount = value;
        },
        handleDateChange({ value }) {
            this.expenseModal.date = value;
        },
        handleDescriptionChange({ value }) {
            this.expenseModal.description = value;
        },
        handleCustomCategoryChange({ value }) {
            this.expenseModal.category = value;
        },
        saveExpense() {
            // Process and save the expense after modal submission
            const amounts = this.expenseModal.amount
                .split(';')
                .map(amount => parseFloat(amount.replace(',', '.')) * 100);
            const category =
                this.selectedCategory?.id === 'custom'
                    ? this.expenseModal.category
                    : this.selectedCategory.value;

            console.log('Expense Category:', category);
            console.log('Expense Amounts:', amounts);

            // Add logic to save expenses and reset modal
            this.handleExpenseModalClose();
        },
        showSettingsModal() {
            // Show settings modal
            this.settingsModal.active = true;
        },
        handleSettingsModalClose() {
            // If needed, reset or close modal on close
            this.settingsModal.active = false;
        },
        saveSettings() {
            // This method is where you'd handle saving the settings
            console.log('Settings Saved:', this.settingsModal.services);

            // Closing the modal after saving
            this.handleSettingsModalClose();
        },
        handleFilterSelect(category) {
            this.selectedFilter = category;
        },
        generateRandomExpenses(count = 30) {
            const entries = [];

            for (let i = 0; i < count; i++) {
                // Randomly decide whether to generate an Expense or Revenue entry
                const isExpense = faker.datatype.boolean();

                // Choose categories based on whether they should be Expense or Revenue
                const allowedCategories = isExpense
                    ? this.categories.filter(
                          c => !c.hidden && (c.type === 'expense' || c.type === undefined),
                      )
                    : this.categories.filter(c => !c.hidden && c.type !== 'expense');

                // Select a random allowed category
                const selectedCategory = faker.helpers.arrayElement(allowedCategories);

                const description = `${selectedCategory.value} Service Fee`;
                const amount =
                    parseFloat(faker.finance.amount({ min: 5, max: 100, fixed: 2 })) * 100;

                entries.push({
                    id: faker.string.uuid(),
                    type: isExpense ? 'Expense' : 'Revenue',
                    description: description,
                    date: faker.date
                        .between({ from: startOfMonth(new Date()), to: new Date() })
                        .toISOString(),
                    amount: amount,
                    service: selectedCategory.value,
                    color: selectedCategory.color,
                });
            }

            // Split the entries into expenses and revenues
            this.expenses = entries.filter(entry => entry.type === 'Expense');
            this.revenues = entries.filter(entry => entry.type === 'Revenue');
        },
        formatEuro(value) {
            return euro(value).format() + ' €';
        },
        navigateToExpenses() {
            this.$router.push({ name: 'EnterExpense' });
        },
        handleRowClick(rowData) {
            console.log(rowData);
        },
    },
    mounted() {
        // Extract employee data based on route params
        const allEmployees = this.$route.params.employees || [];
        this.employee = allEmployees.find(emp => emp.id === this.$route.params.id);
        this.generateRandomExpenses(30);

        if (!this.employee) {
            this.$router.push('/savings-calculator');
        } else {
            this.employee.name = this.employee?.value;
        }

        // Trigger finish loading event
        this.$emit('onFinishLoading');
    },
};
</script>

<style scoped lang="scss">
.EmployeeDetails {
    padding: 20px;

    .PageHeader {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
    }

    .EmployeeName {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .SummaryDashboard {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;
        gap: 10px;

        .SummaryCard {
            background: white;
            border-radius: 8px;
            padding: 10px;
            margin: 0px;
            flex: 1;
            min-width: 220px;
            position: relative;

            h4 {
                font-size: 1rem;
                margin-bottom: 5px;
            }

            h2 {
                margin: 0;
                font-size: 1.5rem;
            }

            .info-icon {
                font-size: 1rem;
                cursor: pointer;
                position: absolute;
                bottom: 2px;
                right: 6px;
                display: inline-block;

                .tooltip {
                    display: none;
                    position: absolute;
                    top: 20px;
                    left: 0;
                    z-index: 10;
                    background: rgba(0, 0, 0, 0.7);
                    color: white;
                    padding: 8px;
                    border-radius: 5px;
                    white-space: nowrap;
                    transform: translateX(-50%);
                    display: block;
                    visibility: visible;
                }
            }
        }

        .SummaryCard .info-icon:hover .tooltip {
            visibility: visible;
            opacity: 1;
        }
    }

    .text-positive {
        color: color(green); /* Green */
    }

    .text-negative {
        color: color(red); /* Red */
    }

    .ActionButtons {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 50px;
        gap: 10px;

        > * {
            margin-left: 20px;
        }
    }
    .ExpensesAndRevenueTableSection {
        display: flex;
        gap: 10px;
    }
    .ExpensesTableSection,
    .RevenueTableSection {
        flex: 1;
        margin-top: 10px;

        h3 {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }

    .FilterButtons {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
    }

    .FilterButton {
        padding: 10px;
        border-radius: 8px;
        border: none;
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
        font-size: 0.75rem;
        color: rgba(0, 0, 0, 0.7);
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover,
        &.isActive {
            background-color: rgba(0, 0, 0, 0.8);
            color: #fff;
        }
    }

    .ChartButtons {
        display: flex;
        gap: 2px;
        margin-bottom: 10px;
    }
    .IconButton {
        padding: 3px;
        border-radius: 3px;
        background: rgba(0, 0, 0, 0);
        cursor: pointer;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px rgba(0, 0, 0, 0);

        &:hover {
            background: rgba(0, 0, 0, 0.2);
            border: solid 1px rgba(0, 0, 0, 0.2);
        }
        &.isActive {
            background: rgba(0, 0, 0, 0.2);
        }
        svg {
            width: 12px;
            height: 12px;
        }
    }
}
.SettingsInputs {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
}

.ServiceInput {
    display: flex;
    align-items: center;
    font-weight: bold;

    label {
        flex: 0.5;
    }

    .InputWrap {
        border: 1px solid #ddd;
        border-radius: 5px;
        text-align: center;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.05);
        width: 120px;
        .Input {
        }
    }
}
</style>
